import React from 'react';

const PageTitle = (props) => {
    return (
        <h1 className='pagetitle'>
            {props.title}
        </h1>
    )
}

export default PageTitle;