import React from "react";
import useContentful from "../hooks/useContentful";
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';
import transitionDown from "../transition/TransitionDown";
import PageTitle from './PageTitle';
import Viewer from "../viewer/Viewer";
import Logo from "../Logo";
import { useParams } from "react-router-dom";

const Project = (props) => {
    const { projectName } = useParams();

    const query = `
    {
        projectPageCollection(where: { slug: "${projectName}" }, limit: 1) {
            items {
                title
                client
                sys {
                    id
                }
                type
                project
                slidesCollection {
                    items {
                    title
                    sys {
                        id
                    }
                    imagesCollection {
                        items {
                        url_small: url(
                            transform: { format: JPG_PROGRESSIVE, height: 300 }
                        )
                        url_large: url(transform: { format: JPG_PROGRESSIVE })
                        sys {
                            id
                        }
                        description
                        }
                    }
                        video {
                            title
                            client
                            mainVideoId
                            previewVideoId
                            slug
                        }
                    }
                }
            }
        }
    }
    `;

    let { data } = useContentful(query);

    if (!data) return <div className=" page-loader"><Logo /></div>;

    const pageTitle = data.projectPageCollection.items[0].title;

    const client = data.projectPageCollection.items[0].client;

    const tabTitle = client + " | " + pageTitle;

    const slides = data.projectPageCollection.items[0].slidesCollection.items;

    const isProject = () => {
        if (data.projectPageCollection.items[0].project === true) {
            return "yes"
        } else {
            return "no"
        }
    };

    console.log()

    return (
        <motion.div 
            className="project-page" 
            id="project-home"
            initial={{ opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 3 } }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
        >
            <Helmet>
                {!client ? (
                    <title>Mat+Kat | {pageTitle}</title>
                ) : (
                    <title>Mat+Kat | {tabTitle}</title>
                )}
            </Helmet>
            <PageTitle className="project-title" title={pageTitle}/>
            <h3 className="project-client">{client}</h3>
            <Viewer classname={"project-viewer"} slides={slides} isProject={isProject()} />
        </motion.div>
    );
}

export default transitionDown(Project);