import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const menu = {
    closed: {
        x: "57vmin",
        transition: {
            ease: "linear",
            staggerChildren: 0.1,
            staggerDirection: 1,
            when: "afterChildren"
        }
    },
    open: {
        x: 0,
        transition: {
            ease: "linear",
            staggerChildren: 0.05,
            staggerDirection: -1,
        }
    }
}

const link = {
    closed: {
        x: "100vw",
        opacity: 1,
        rotateZ: 180,
        transition: {
            ease: "linear"
        }
    },
    open: {
        x: 0,
        opacity: 1,
        rotateZ: 180,
        transition: {
            ease: "linear"
        }
    }
}

const text = {
    closed: {
        x: "0px"
    },
    open: {
        x: "0px"
    },
    tap: {
        y: "1rem"
    }
}

const NavMenu = ({close}) => {

    return (
        <motion.ul 
            className="navmenu"
            variants={menu}
            initial="closed"
            animate="open"
            exit="closed"
            >
            <motion.li
                className="nav-link"
                key="stills"
                variants={link}
                >
                <Link
                    to="work" 
                    replace 
                    onClick= {() => {close();}}
                    >
                    <motion.h1 
                        variants={text}
                        whileTap="tap"
                        whileHover="tap"
                        >
                        work
                    </motion.h1>
                </Link>
            </motion.li>
            <motion.li
                className="nav-link"
                key="motion"
                variants={link}
                >
                <Link
                    to="motion" 
                    replace 
                    onClick= {() => {close();}}
                    >
                    <motion.h1 
                        variants={text}
                        whileTap="tap"
                        whileHover="tap"
                        >
                        motion
                    </motion.h1>
                </Link>
            </motion.li>
            <motion.li
                className="nav-link"
                key="about"
                variants={link}
                >
                <Link
                    to="about" 
                    replace 
                    onClick= {() => {close();}}
                    >
                    <motion.h1 
                        variants={text}
                        whileTap="tap"
                        whileHover="tap"
                        >
                        about
                    </motion.h1>
                </Link>
            </motion.li>
            <motion.li variants={link} key="spacer" style={{ width: "72px", backgroundColor: "$white" }} />
        </motion.ul>
    );
}

export default NavMenu;