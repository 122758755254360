import {motion} from "framer-motion";

const transitionDown = (Component) => {
    return () => (

        <>
            <Component/>
            <motion.div
                className="slide-in-down"
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 1 }}
                transition={{ duration: 3, ease: [0.22, 1, 0.36, 1] }}
            />
            <motion.div
                className="slide-out-down"
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
            />
        </>
    )
}

export default transitionDown;