import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Navmenu from './Navmenu';
import Logo from '../Logo';



export default function Nav() {
    const [isActive, setActive] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const logoReset = () => {
        setOpen(null);
        setActive(false);
    }

    const handleMenu = () => {
        setActive(!isActive);
        setOpen(!isOpen);
    }

    let btnClass = [
        'menu-btn',
        isActive ? ' active' : ''
    ]
    btnClass = btnClass.join('')

    return (
        <div className="nav-bar">
            <Link 
                to="/"
                replace 
                onClick= {logoReset}
            >
                <Logo width="40"/>
            </Link>
            <button
                onClick= {handleMenu}
                type="button"
                className= {btnClass}
                data-target="#menu"
                aria-expanded="false"
                aria-controls="menu"
            >
                <span/>
                <span/>
            </button>
            <AnimatePresence>
                {isOpen && (<Navmenu close={()=>handleMenu()} />)}
            </AnimatePresence>
        </div>
    );
}