import React from "react";
import { ReactComponent as gridview } from "./gridview.svg";
import { ReactComponent as nextchevron } from "./nextchevron.svg";
import { ReactComponent as prevchevron } from "./prevchevron.svg";
import { ReactComponent as smptebars } from "./smptebars.svg";

const iconTypes = {
    gridview: gridview,
    nextchevron: nextchevron,
    prevchevron: prevchevron,
    smptebars: smptebars
};

const Icon = ({ name, ...props }) => {
    let Icon = iconTypes[name];
    return <Icon {...props} />;
};

export default Icon;