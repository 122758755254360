import React from 'react'
import { Link } from 'react-router-dom';

const ViewerFooter = ({setGrid, isGrid, slides, slideIndex, isProject}) => {


    const caption1 = () => {
        if (slides[slideIndex].imagesCollection.items.length === 0) {
            return <p>{slides[slideIndex].video.title}, {slides[slideIndex].video.client}</p>;
        } else {
            return <p>{slides[slideIndex].imagesCollection.items[0].description}</p>;
        }
    }

    const caption2 = () => {
        if (slides[slideIndex].imagesCollection.items.length <= 1) {
            return null;
        } else if (slides[slideIndex].imagesCollection.items[0].description === slides[slideIndex].imagesCollection.items[1].description){
            return null;
        } else {
            return <p>{slides[slideIndex].imagesCollection.items[1].description}</p>;
        }
    }

    console.log()

    return (
        <div className='viewer-footer'>
            <div className='footer-item project-mgmt'>
                <div className='grid-btn btn'
                    type='button'
                    onClick={() => setGrid(!isGrid)}
                    >
                    {isGrid ? (
                        <p>Slideshow</p>
                    ):(
                        <p>Index</p>
                    )}
                </div>
                {isGrid || isProject === "yes" ? (null):(
                    <Link
                        className="project-btn btn"
                        type="button"
                        to={!slides[slideIndex].project ? (null) : (slides[slideIndex].project.slug)}
                    >
                        {slides[slideIndex].project !== null ? (<p>View Project</p>) : (null)}
                    </Link>
                )}
                {!slides[slideIndex].video ? (null) : (
                    <Link
                        className='project-btn btn'
                        type="button"
                        to={"/motion/" + slides[slideIndex].video.slug}
                    >
                        {slides[slideIndex].project !== null ? (null) : (<p>View Project</p>)}
                    </Link>
                )}
                {isProject === "yes" ? (
                    <Link
                        className="project-btn btn"
                        type="button"
                        to="/work"
                    >
                        <p>&lt; Back</p>
                    </Link>
                ):(null)}
            </div>
            {isProject === "yes" ? (null): (
                <div className='footer-item description'>
                    {isGrid ? (null):(
                        <>
                            {caption1()}
                            {caption2()}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default ViewerFooter