import React, {useState} from 'react';
import ViewerImage from './ViewerImage';
import ViewerFooter from './ViewerFooter';

const Viewer = (props) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [isGrid, setGrid] = useState(1);

    return (
        <div className={props.classname}>
            <ViewerImage slideIndex={slideIndex} index={setSlideIndex} slides={props.slides} grid={isGrid} setGrid={setGrid}/>
            <ViewerFooter setGrid={setGrid} isGrid={isGrid} slideIndex={slideIndex} setIndex={setSlideIndex} slides={props.slides} isProject={props.isProject}/>
        </div>
    )
}

export default Viewer;