import React from "react";

const Logo = ({
    style = {},
    fill = {},
    width = "100%",
    height = "100%",
    className = "logo",
    viewBox = "0 0 500 520"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="matkat-logo">
            <g>
                <path id="T" fill={fill} d="M436.44,113.84L436.44,113.84c0,1.56,0.51,3.09,1.45,4.34c9.34,12.33,14.21,20.49,19.97,30.53
                    l0,226.76c0,3.87,2.9,7.33,6.76,7.54c4.12,0.22,7.53-3.06,7.53-7.13V180.69c3.83,10.1,7.03,22.06,9.1,31.6
                    c0.72,3.32,3.68,5.66,7.07,5.66l0,0c4.56,0,8.28-4.23,7.12-8.64c-3.51-13.28-6.37-28.96-18.94-55.63
                    c-7.9-16.75-16.65-30.42-27.15-44.37C445.22,103.84,436.44,106.99,436.44,113.84z"/>
                <path id="A" fill={fill} d="M400.7,70.09L400.7,70.09c-15.76,0-28.52,12.32-28.54,28.09v369.47c0,3.93,3.19,7.12,7.12,7.13
                    c3.94,0,7.13-3.19,7.13-7.13v-200.4l28.59-0.02v166.43c0,3.94,3.2,7.14,7.14,7.14s7.14-3.2,7.14-7.14V98.18
                    C429.31,82.39,416.49,70.09,400.7,70.09z M415.02,252.95h-28.56V98.18c0.02-7.88,6.38-13.81,14.26-13.81
                    c7.9,0,14.32,5.91,14.31,13.81V252.95z"/>
                <path id="K" fill={fill} d="M343.56,238.43V67.95c0-3.94-3.2-7.14-7.14-7.14c-3.94,0-7.14,3.2-7.14,7.14v170.71
                    c0,7.79-6.24,14.12-14,14.27c-0.1,0-0.19-0.01-0.28-0.01h-14.28V53.7c0-3.94-3.2-7.14-7.14-7.14c-3.94,0-7.14,3.2-7.14,7.14
                    v199.22l-0.05,247.66c0,3.94,3.2,7.14,7.14,7.14c3.94,0,7.14-3.2,7.14-7.14L300.71,267H315c0.1,0,0.19-0.01,0.28-0.01
                    c7.76,0.15,14,6.48,14,14.27l0,207.75c0,3.94,3.2,7.14,7.14,7.14s7.14-3.2,7.14-7.14l0-207.51c0-8.6-3.82-16.29-9.83-21.53
                    C339.74,254.72,343.56,247.03,343.56,238.43z"/>
                <path id="T" fill={fill} d="M132.91,57.43c33.44-19.1,72.21-30.93,110.72-31.74v478.67c0,3.94,3.2,7.14,7.14,7.14h0
                    c3.94,0,7.14-3.2,7.14-7.14l0.03-478.67c38.61,0.76,77.26,12.34,110.66,31.64c4.76,2.75,10.7-0.7,10.7-6.19v0
                    c0-2.55-1.34-4.92-3.55-6.19C340.77,24.86,299.11,11.5,250.83,11.5c-48.41,0-90.08,13.36-125.06,33.52
                    c-2.2,1.27-3.54,3.64-3.54,6.19v0C122.22,56.7,128.14,60.15,132.91,57.43z"/>
                <path id="A" fill={fill} d="M186.49,46.56L186.49,46.56c-15.77,0-28.56,12.78-28.57,28.56l0.02,413.67
                    c0,3.95,3.2,7.14,7.14,7.14c3.94,0,7.14-3.19,7.14-7.14l-0.01-221.56h28.57v233.35c0,3.94,3.2,7.14,7.14,7.14
                    c3.94,0,7.14-3.2,7.14-7.14l-0.02-425.45C215.06,59.35,202.27,46.56,186.49,46.56z M200.71,252.95h-28.5V75.12
                    c0-7.89,6.39-14.28,14.28-14.28h0c7.89,0,14.28,6.4,14.28,14.29L200.71,252.95z"/>
                <path id="M" fill={fill} d="M100.8,70.09c-15.78,0-28.56,12.79-28.57,28.56l0,29.94c-16.92-8.92-42.85-0.58-42.85,25.09V375.7
                    c0,3.94,3.2,7.14,7.14,7.14c3.94,0,7.14-3.2,7.14-7.14V153.68c0.1-19.02,28.67-19.02,28.56,0l-0.07,279.75
                    c0,3.94,3.2,7.14,7.14,7.14c3.94,0,7.14-3.2,7.14-7.14l0.07-279l0,0l0-55.77c0-7.89,6.39-14.28,14.28-14.28
                    c7.89,0,14.28,6.39,14.28,14.28l0,368.77c0,3.95,3.2,7.15,7.15,7.14c3.94-0.01,7.13-3.2,7.13-7.14l0-368.77
                    C129.37,82.87,116.58,70.09,100.8,70.09z"/>
            </g>
        </g>
    </svg>
);

export default Logo;