import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()


const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App tab="home" />);