import {React} from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { AnimatePresence } from "framer-motion";
import Nav from './components/nav/Nav';
import './scss/styles.scss';

import Home from './components/pages/Home';
import Project from './components/pages/Project2';
import MotionProject from './components/motion/MotionProject';
import Motion from './components/pages/Motion';
import About from './components/pages/About';
import Landing from './components/pages/Landing';

const HeaderLayout = () => (
  <>
    <header>
      <Helmet>
        <title>Mat+Kat</title>
        <meta name="description" content="photo and directing duo based in NYC" />
        <meta name="keywords" content="Mat+Kat, Mat and Kat, Mat&amp;Kat, MatKat, photography, photographer, photographers, director, directors, artists, artist, duo, motion, video, fashion, portrait, still life, advertising, New York City, NYC"></meta>
        <meta name="robots" content="index, follow"></meta>
        <meta name="googlebot" content="index, follow"></meta>
        <meta name="theme-color" content="#c2000b"></meta>
      </Helmet>
      <Nav />
    </header>
    <AnimatePresence>
      <Outlet />
    </AnimatePresence>
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HeaderLayout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: 'work',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Home />
          },
          { 
            path: ':projectName',
            element: <Project />
          }
        ],
      },
      {
        path: 'motion',
        element: <Motion />,
        children: [
          { 
            path: ':projectName',
            element: <MotionProject />
          }
        ],
      },
      {
        path: 'about',
        element: <About />,
      }
    ],
  },
]);

function App(props) {

  return (
    <div className='App'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;