import React from "react";
import { Outlet } from "react-router-dom";
import useContentful from "../hooks/useContentful";
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';
import transitionUp from "../transition/TransitionUp";
import Logo from "../Logo";
import PageTitle from './PageTitle';
import Viewer from '../viewer/Viewer';

const query = `
{
    workCollection(limit: 1) {
        items {
            title
            slidesCollection {
                items {
                    title
                    imagesCollection {
                        items {
                            description
                            url_small: url(
                            transform: { format: JPG_PROGRESSIVE, height: 300 }
                            )
                            url_large: url(transform: { format: JPG_PROGRESSIVE })
                            sys {
                                id
                            }
                        }
                    }
                    video {
                        title
                        client
                        mainVideoId
                        previewVideoId
                        slug
                    }
                    project {
                        title
                        slug
                        client
                    }
                    projectType
                    sys {
                        id
                    }
                }
            }
        }
    }
}
`;

function Home() {
    let { data } = useContentful(query);

    if (!data ) return <div className="page-loader"><Logo /></div>;

    const pageTitle = data.workCollection.items[0].title;

    const slides = data.workCollection.items[0].slidesCollection.items;

    console.log()

    return (
        <motion.div 
            className="home-page" 
            id="stills-home"
            initial={{ opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 2 } }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
        >
            <Helmet>
                <title>Mat+Kat | {pageTitle}</title>
            </Helmet>
            <PageTitle title={pageTitle}/>
            <Viewer classname={"viewer"} slides={slides} />
        </motion.div>
    );
}

export default Home;