import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { isMobileOnly } from 'react-device-detect';
import Vimeo from '@u-wave/react-vimeo';
import useContentful from "../hooks/useContentful";
import Logo from "../Logo";

const query = `{
    landingVideoCollection {
        items {
            title
            videoId
            itemId
            holder {
                url
                title
            }
        }
    }
}`

const videoslide = {
    hidden: { 
        y: "-250%",
        opacity: 1,
        transition: { type: "spring", stiffness: 30 }
    },
    visible: { 
        y: 0,
        opacity: 1,
        delay: 1
    }
};

const text = {
    initial: {
        y: -10,
        opacity: 0
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 2,
            duration: 0.3
        }
    }
}

const options = {
    threshold: 0.7
}

const Landing = () => {
    const navigate = useNavigate();
    const [entered, setEnter] = useState(false);
    const { ref: myRef, inView: visible } = useInView(options);

    useEffect(() => {
        if (visible) {
            setEnter(entered)
            return navigate("/work");
        } 
    }, [ navigate, visible, entered ]);

    let { data } = useContentful(query);
    if (!data) return null;
    console.log(data);

    const homesettings = {
        background: false,
        loop: true,
        responsive: true,
        paused: false,
        muted: false,
        autoplay: true,
        volume: 0,
        pip: false,
        controls: false
    }

    const desktopVideo = data.landingVideoCollection.items[0];

    const mobileVideo = data.landingVideoCollection.items[1];

    return (
        <>
            <motion.div 
                className="home-video"
            >
                <motion.div
                    variants={videoslide}
                    initial="visible"
                    animate={!entered ? "visible" : "hidden"}
                >
                    <motion.div
                        className='landing-text'
                        variants={text}
                        initial="initial"
                        animate="animate"
                        >
                        <div className='landing-text-top'>
                            <h1>Mat+Kat</h1>
                            <div className='landing-text-small'>
                                <h3>photo + directing</h3>
                                <h3>based in NYC</h3>
                            </div>
                        </div>
                        <div className='landing-text-contact'>
                            <a className="email" href="mailto:studio@matkat.co">studio@matkat.co</a>
                            <a href="https://www.instagram.com/_matkat/">@_matkat</a>
                            <p className='rep'>representation: NEER Films</p>
                            <a href="mailto: malin@neermotion.com?cc=chandra@neermotion.com">malin@neermotion.com</a>
                            <a href="mailto: chandra@neermotion.com?cc=malin@neermotion.com">chandra@neermotion.com</a>
                        </div>
                    </motion.div>
                    <motion.div
                        className='scroll-btn'
                        variants={text}
                        initial="initial"
                        animate="animate"
                        onClick={() => {setEnter(true)}}
                        >
                        <p>scroll</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z"/></svg>
                    </motion.div>
                    <div>
                        <img className="static" src={desktopVideo.holder.url} alt={desktopVideo.holder.title}/>
                    </div>
                    <div className='gradient' />
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.5, duration: 0 }}
                    >
                        <Vimeo {...homesettings}
                            className= {isMobileOnly ? ("home-playr-mobile") : ("home-playr-desktop")}
                            video= {isMobileOnly ? (mobileVideo.videoId) : (desktopVideo.videoId)}
                            width= "100%"
                            height= "100%"
                            quality= "360p"
                            start= {0}
                            volume= {0}
                        />
                    </motion.div>
                </motion.div>
                <motion.div 
                    variants={videoslide}
                    initial="visible"
                    animate={!entered ? "visible" : "hidden"}
                    className="video-spacer" 
                    ref={myRef}/>
            </motion.div>
            <motion.div
                className='home-logo'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
            >
                <Logo />
            </motion.div>
        </>
    );
}

export default Landing;